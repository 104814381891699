export default class MostRecentLoadMoreRepository {

    #document;
    #results;
    #endOfListReached = false;

    constructor(document) {
        this.#document = document;
    }

    getMore = async(uri, getMoreQty = 8, exclude = "", lastDisplayedStoryId = "") => {
        const results = await this.#fetchMore(uri, exclude);
        const lastDisplayedIndex = this.#getLastDisplayedIndex(results, lastDisplayedStoryId);
        const offset = this.#getOffset(lastDisplayedIndex);
        this.#setEndOfListReached(offset, getMoreQty, results);

        return results.slice(offset, offset + getMoreQty);
    };

    endOfListReached = () => {
        return this.#endOfListReached;
    };

    #setEndOfListReached = (offset, getMoreQty, results) => {
        if (offset + getMoreQty >= results.length) {
            this.#endOfListReached = true;
        }
    };

    #getOffset = lastDisplayedIndex => {
        return lastDisplayedIndex === -1 ? 0 : lastDisplayedIndex + 1;
    };

    #getLastDisplayedIndex = (results, lastDisplayedStoryId) => {
        return results.findIndex(elem => {
            return elem.querySelector("[data-target-type=story], [data-target-type=dossier]").dataset.targetId === lastDisplayedStoryId;
        });
    };

    #fetchMore = (uri, exclude) => {
        if (this.#results) {
            return this.#results;
        }

        this.#results = [];
        return fetch(uri)
            .then(async res => {
                if (!res.ok) {
                    throw Error(res.statusText);
                }

                const html = await res.text();
                const wrapper = this.#document.createElement("div");
                wrapper.innerHTML = html;

                /** @type {(NodeList)} */
                const articles = wrapper.querySelectorAll("li");

                this.#results = Array.from(articles).filter(element => {
                    return element.querySelector("[data-target-type=story], [data-target-type=dossier]").dataset.targetId !== exclude;
                });

                return this.#results;
            });
    };
}
