import HandlerAbstract from "./HandlerAbstract";

export default class TimeSpentHandler extends HandlerAbstract {

    /**
     * @private
     * @member {number}
     */
    _timeSpentInSeconds = 0;

    /**
     * @private
     * @member {number}
     */
    _timerId;

    /**
     * @constant
     * @member {number}
     */
    TIME_INTERVAL = 1000;

    /**
     * @member {string}
     */
    _deviceType;

    /**
     * @param {string} deviceType
     */
    constructor(deviceType) {
        super(null);

        this._deviceType = deviceType;

        this._tick = this._tick.bind(this);
    }

    init(window, analytics) {
        super.init(window, analytics);

        this._startTimer();
    }

    getEventHandlers() {
        let supportedHandlers = ["load", "beforeunload", "visibilitychange"];

        if (this._deviceType === "desktop") {
            supportedHandlers.push("focus", "blur");
        }

        return supportedHandlers;
    }

    handleLoad() {
        this._restartTimer();
    }

    handleBeforeunload() {
        this._pauseTimer();

        this._analytics.pubSub.publish("timeSpent", {
            "duration": Math.round(this._timeSpentInSeconds)
        });
    }

    handleFocus() {
        this._startTimer();
    }

    handleBlur() {
        this._pauseTimer();
    }

    handleVisibilitychange() {
        if (this._domDocument.hidden) {
            this._pauseTimer();
        } else {
            this._startTimer();
        }
    }

    _tick() {
        this._timeSpentInSeconds += this.TIME_INTERVAL / 1000;
    }

    _startTimer() {
        if (this._timerId !== null) {
            this._pauseTimer();
        }
        this._timerId = this._domWindow.setInterval(this._tick, this.TIME_INTERVAL);
    }

    _pauseTimer() {
        this._domWindow.clearInterval(this._timerId);
        this._timerId = null;
    }

    _stopTimer() {
        this._pauseTimer();
        this._timeSpentInSeconds = 0;
    }

    _restartTimer() {
        this._stopTimer();
        this._startTimer();
    }
}
