
import HandlerAbstract from "./HandlerAbstract";
import LinkClickedParamBuilder from "../LinkClickedParamBuilder";

export default class ClickHeadlinesHandler extends HandlerAbstract {

    getEventHandlers() {
        return ["mouseup"];
    }

    handle(evt) {

        const target = evt.target.closest("input");
        const linkClicked = new LinkClickedParamBuilder()
            .setType("newsletter")
            .setObjectId(target.closest("[data-object-id]").dataset.objectId)
            .setEventAction("inscriptionCourriel")
            .build({type: true, objectId: true});

        this._analytics.pubSub.publish("linkClicked",linkClicked);

        return false;
    }
}
