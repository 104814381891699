
import HandlerAbstract from "./HandlerAbstract";

export default class ClickLoadMoreButtonHandler extends HandlerAbstract {

    getEventHandlers() {
        return ["mouseup"];
    }

    handle(evt) {

        const target = evt.target;

        this._analytics.pubSub.publish("buttonClicked", {
            "actionCode": "loadMore",
            "actionParams": {
                "clickCount": parseInt(target.closest("[data-click-count]").dataset.clickCount) + 1
            },
            "label": this.getTargetLabel(target),
            "location": target.closest("[data-location]").dataset.location,
        });

        return false;
    }
}
