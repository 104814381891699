
import ScrolledToBeginOfBlockHandler from "./ScrolledToBeginOfBlockHandler";

export default class ScrolledToBlockItemHandler extends ScrolledToBeginOfBlockHandler {

    /**
     * @private
     * @var {string}
     */
    _itemSelector;

    /**
     * @private
     * @var {number}
     */
    _itemReached = -1;

    /**
     *
     * @param {string} selector - css selector of the dom to watch
     * @param {string} blockName - name of the block (analytics event)
     * @param {string} itemSelector
     * @param {function | null} targetExtractor
     */
    constructor(selector, blockName, itemSelector, targetExtractor = null) {
        super(selector, blockName);

        this._itemSelector = itemSelector;
        this._targetExtractor = targetExtractor;
    }

    _triggerEvent() {
        let bottomPosition = this._calculateBottomPosition();
        let items = Array.prototype.slice.call(this._targetNode.querySelectorAll(this._itemSelector));
        let i = items.length - 1;

        while (i >= 0) {
            let node = items[i];
            if (node && bottomPosition > this._calculateTargetTopPosition(node) && i > this._itemReached) {
                this._itemReached = i;
                let position = node.getAttribute("data-position");
                let type;
                let id;
                let targetItemNode = this._targetExtractor && this._targetExtractor(node);
                if (targetItemNode) {
                    type = targetItemNode.closest("[data-target-type]").dataset.targetType;
                    id = targetItemNode.closest("[data-target-id]").dataset.targetId;
                }

                this._analytics.pubSub.publish("scrolledToBlockItem", {
                    name: this._blockName,
                    reach: {
                        position: position,
                        itemType: type,
                        itemId: id
                    }
                });

                break;
            }
            i--;
        }
    }
}
