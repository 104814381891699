import HandlerAbstract from "./HandlerAbstract";
import debounce from "lodash.debounce";

export default class ScrolledToBeginOfBlockHandler extends HandlerAbstract {
    /**
     * @protected
     * @var {string}
     */
    _blockName;

    /**
     * @protected
     * @var {string}
     */
    _targetSelector;

    /**
     * @protected
     * @var {Element}
     */
    _targetNode;

    /**
     * @private
     * @var {boolean}
     */
    _hasTriggerEvent = false;

    /**
     *
     * @param {string} selector - css selector of the dom node to watch
     * @param {string} blockName - name of the block (analytics event)
     */
    constructor(selector, blockName) {
        super(null);

        this._blockName = blockName;
        this._targetSelector = selector;

        this.handle = debounce(this.handleScroll, 50);
    }

    init(window, analytics) {
        super.init(window, analytics);

        this._targetNode = this._domDocument.querySelector(this._targetSelector);
    }

    getEventHandlers() {
        if (this._targetNode)
            return "scroll";
        else
            return [];
    }

    handleScroll() {
        if (this._hasReachedBlock()) {
            this._triggerEvent();
        }
    }

    _hasReachedBlock() {
        return this._calculateBottomPosition() > this._calculateTargetTopPosition();
    }

    _calculateTargetTopPosition(target = null) {
        let currentTarget = target || this._targetNode;
        let topPosition = currentTarget.offsetTop;
        let node = currentTarget.offsetParent;

        while (node) {
            topPosition += node.offsetTop;
            node = node.offsetParent;
        }

        return topPosition;
    }

    _calculateBottomPosition() {
        return this._domDocument.documentElement.scrollTop + (this._domWindow.innerHeight || this._domDocument.documentElement.clientHeight);
    }

    _triggerEvent() {
        if (!this._hasTriggerEvent) {
            this._hasTriggerEvent = true;

            this._analytics.pubSub.publish("scrolledToBeginOfBlock", {
                name: this._blockName,
            });

        }
    }
}
