import HandlerAbstract from "./HandlerAbstract";
import sha1 from "sha1";

export default class NewsletterHandler extends HandlerAbstract {
    getEventHandlers() {
        return ["newsletter.subscriptionCompleted"];
    }

    handle(event) {
        const userEmail = event.detail.userEmail;
        const emailSHA1Encoded = sha1(userEmail);
        const newsletterId = event.detail.newsletterId;

        this._analytics.pubSub.publish("newsletterSubscriptionCompleted", {
            "eh": emailSHA1Encoded,
            "newsletterId": newsletterId,
        });

        return false;
    }
}
